import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { createLogger } from 'redux-logger'

import rootReducer from './reducers'
const logger = createLogger()

const store =
  process.env.NODE_ENV == 'development'
    ? configureStore({
        reducer: rootReducer,
        devTools: true,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
        // Other middleware or configuration options can be added here
      })
    : configureStore({
        reducer: rootReducer,
      })

export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector

export default store
